import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Breadcrumb, Button, Checkbox, Col, Form, Input, Row, Select, Space, Radio } from 'antd';
import eiqSecureStore from '../../stores/eiq-secure.store';
import { ReactComponent as AdmitIcon } from 'assets/icons/admit.svg';
import { ReactComponent as BackIcon } from 'assets/icons/back.svg';
import { FormWrapper } from '../../../../common/components/form-wrapper/form-wrapper';
import './eiq-secure-resident-license.scss';
import guestService from '../../../../common/services/guest-service/guest.service';
import { notification } from '../../../../common/utils/notification';
import { formatAddressLabel } from '../../../../common/helpers/address.helper';
import configurationStore from 'common/stores/configuration/configuration.store';
import { eiqSecureResidentLicenseValidation } from './eiq-secure-resident-license.validation';
import { noAddressInstructionsMessage, toGateOptions } from 'features/eiq-secure/constans/common.eiq-secure';
import { useTableContext } from '../../../../common/components/table/table-context';
import { vehicleInfoBlock } from '../../../../common/components/vehicle-info-block/vehicle-info-block';
import userAuthStore from 'common/stores/user-auth/user-auth.store';
import { useMedia } from '../../../../common/hooks/use-media/use-media.hook';
import Footer from '../../../../common/components/footer/footer';
import { setResidentListSearchFilter } from 'common/helpers/search-filter.helper';
import { VehicleStatusEnum } from 'common/enums/vehicle-status.enum';
import { EntryType } from 'common/enums/entry-type.enum';
import SelectState from 'common/components/form-elements/select-state/select-state';
import TextArea from 'antd/es/input/TextArea';

interface IEiqSecureResidentLicenseProps {
  residentLicenseAdmit: boolean;
}

export const EiqSecureResidentLicense = observer(({ residentLicenseAdmit }: IEiqSecureResidentLicenseProps) => {
  const tableContext = useTableContext();
  const { isMobileOrTable, isMobile } = useMedia();

  const [form] = Form.useForm();
  const entryTypeValue = Form.useWatch('entryType', form);

  const defaultLicenseState = configurationStore.configuration?.default.defaultLicenseState;

  const gateOptions = useMemo(() => toGateOptions(configurationStore?.configuration?.gates || []), []);

  const vehicles = useMemo(() => {
    return eiqSecureStore.residentDetails?.addresses.find((addressItem) => addressItem?.address?.id === eiqSecureStore.selectedAddressId)
      ?.vehicles;
  }, [eiqSecureStore.selectedAddressId, eiqSecureStore.residentDetails]);

  const onFinish = (values: any) => {
    const { vehicleId, gate, carLicensePlate, carState, entryType, note } = values;

    const vehicle = vehicles?.find((vehicle) => vehicle.id === vehicleId);

    if (entryType === EntryType.Driver && !vehicle) {
      return;
    }

    const admitResidentBody = {
      entryType,
      gate,
      carState: entryType === EntryType.Driver ? vehicle?.licenseState : carState,
      carLicensePlate: entryType === EntryType.Driver ? vehicle?.licenseNumber : carLicensePlate,
      carExpirationDate: vehicle?.validTo,
      deviceId: vehicle?.deviceId,
      driverCompanyName: entryType === EntryType.Passenger ? values.driverCompanyName : null,
      driverName: entryType === EntryType.Passenger ? `${values.driverFirstName ?? ''} ${values.driverLastName ?? ''}`.trim() : null,
      note: entryType === EntryType.Other ? note : null,
    };

    const residentId = eiqSecureStore?.residentDetails?.id;
    const selectedAddressId = eiqSecureStore?.selectedAddressId;

    if (!residentId || !selectedAddressId) {
      return;
    }

    return guestService.admitResident(residentId, selectedAddressId, admitResidentBody).then(() => {
      notification.success({ message: 'The resident  has been admitted' });
      tableContext.setSelectedRow(null);
      eiqSecureStore.setAdmitFlow(null);
      eiqSecureStore.setIsAdmitFlowProcessed();
      setResidentListSearchFilter('');
    });
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  useEffect(() => {
    if (residentLicenseAdmit) handleClickAdmit();
  }, [residentLicenseAdmit]);

  const handleClickAdmit = () => {
    form.submit();
  };

  const licenseOptions = useMemo(() => {
    return vehicles?.map((vehicle) => ({
      label: vehicleInfoBlock(vehicle),
      value: vehicle.id,
      isApproved: vehicle.status === VehicleStatusEnum.Approved,
    }));
  }, [eiqSecureStore.selectedAddressId, eiqSecureStore.residentDetails]);

  const entryTypeOptions = [
    {
      label: 'Driver',
      value: EntryType.Driver,
      isApproved: false,
    },
    {
      label: 'Pedestrian',
      value: EntryType.Pedestrian,
      isApproved: false,
    },
    {
      label: 'Passenger',
      value: EntryType.Passenger,
      isApproved: false,
    },
    {
      label: 'Other',
      value: EntryType.Other,
      isApproved: false,
    },
  ];

  const admitActionsBtn = (
    <>
      <Button type="primary" className="primary-btn" icon={<AdmitIcon />} onClick={handleClickAdmit}>
        Approve
      </Button>
    </>
  );

  const onEntryTypeChanged = (newValue: EntryType) => {
    if (newValue === EntryType.Passenger) {
      form.setFieldValue('carLicensePlate', '');
      form.setFieldValue('carState', defaultLicenseState);
    }
    if (newValue === EntryType.Driver) {
      form.setFieldValue('carLicensePlate', eiqSecureStore.selectedGuest?.carLicensePlates.find((i) => i.isPrimary)?.number);
      form.setFieldValue('carState', eiqSecureStore.selectedGuest?.carLicensePlates.find((i) => i.isPrimary)?.state ?? defaultLicenseState);
    }
  };

  return (
    <div className="resident-license">
      <Row justify="space-between">
        <Col>
          <div className="space-12">
            <div className="breadcrumb-wrapper">
              <BackIcon
                onClick={() => {
                  eiqSecureStore.setAdmitFlow(null);
                }}
              />
              <Breadcrumb
                separator="–"
                items={[
                  {
                    title: 'License',
                  },
                ]}
              />
            </div>
            <span className="sub-title">{formatAddressLabel(eiqSecureStore.getSelectedAddress())}</span>
          </div>
        </Col>
        <Col>
          <Space size={8}>{!isMobileOrTable && admitActionsBtn}</Space>
        </Col>
      </Row>

      <div className="container-row">
        <Row gutter={32} className="">
          <Col xs={24} lg={12} className="blue-alert">
            {eiqSecureStore.getSelectedAddress()?.instructions || noAddressInstructionsMessage}
          </Col>
          <Col xs={24} lg={12}>
            <FormWrapper form={form} onFinish={onFinish} layout="vertical">
              <Form.Item
                name="entryType"
                label="Entry Type"
                initialValue={entryTypeOptions?.find((i) => i.isApproved)?.value || entryTypeOptions?.[0]?.value}>
                <Select
                  options={entryTypeOptions}
                  placeholder="Select Entry Type"
                  popupClassName="so-select"
                  onChange={onEntryTypeChanged}
                />
              </Form.Item>

              {entryTypeValue === EntryType.Passenger && (
                <>
                  <Form.Item name="driverFirstName" label="Driver first name">
                    <Input placeholder="Enter driver first name" autoFocus />
                  </Form.Item>
                  <Form.Item name="driverLastName" label="Driver last name">
                    <Input placeholder="Enter driver last name" />
                  </Form.Item>
                  <Form.Item
                    name="driverCompanyName"
                    label="Driver company name"
                    rules={eiqSecureResidentLicenseValidation.driverCompanyName}>
                    <Input placeholder="Enter driver company name" />
                  </Form.Item>
                </>
              )}

              {entryTypeValue === EntryType.Driver && (
                <Form.Item
                  name="vehicleId"
                  label="License"
                  initialValue={licenseOptions?.find((i) => i.isApproved)?.value || licenseOptions?.[0]?.value}
                  rules={eiqSecureResidentLicenseValidation.carLicensePlate}>
                  <Select options={licenseOptions} placeholder="Select License" popupClassName="so-select" />
                </Form.Item>
              )}

              {entryTypeValue === EntryType.Passenger && (
                <>
                  <Form.Item name="carLicensePlate" label="License" rules={eiqSecureResidentLicenseValidation.carLicensePlate}>
                    <Input placeholder="Enter license" />
                  </Form.Item>

                  <Form.Item
                    name="carState"
                    label="License state"
                    initialValue={defaultLicenseState}
                    rules={eiqSecureResidentLicenseValidation.carState}>
                    <SelectState placeholder="Select a license state" popupClassName="so-select" />
                  </Form.Item>
                </>
              )}

              {entryTypeValue === EntryType.Other && (
                <Form.Item name="note" label="Notes">
                  <TextArea placeholder="Enter notes" maxLength={500} style={{ resize: 'none' }} autoFocus />
                </Form.Item>
              )}

              <Form.Item
                name="gate"
                label="Gate"
                rules={eiqSecureResidentLicenseValidation.gate}
                initialValue={userAuthStore.defaultGate ? userAuthStore.defaultGate : undefined}>
                <Select options={gateOptions} placeholder="Select Gate" popupClassName="so-select" />
              </Form.Item>
            </FormWrapper>
          </Col>
        </Row>
      </div>

      {isMobileOrTable && <Footer>{admitActionsBtn}</Footer>}
    </div>
  );
});
