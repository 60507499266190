import { useImperativeHandle, useState } from 'react';
import { IUseIncidentFormProps } from './use-incident-form.types';
import { useForm } from 'antd/es/form/Form';

export const useIncidentForm = ({ initialValues, onSave, ref }: IUseIncidentFormProps) => {
  const [form] = useForm();
  const [currentMode, setCurrentMode] = useState<'view' | 'edit'>(initialValues ? 'view' : 'edit');

  const switchToEditMode = () => setCurrentMode('edit');
  const switchToViewMode = () => setCurrentMode('view');

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      debugger;
      form.submit();
      return form.validateFields().then(onSave);
    },
  }));

  const handleSubmit = () => {
    form.submit();
    form.validateFields().then((values) => {
      onSave(values);

      if (initialValues) {
        switchToViewMode();
      }
    });
  };

  return {
    form,
    mode: currentMode,
    switchToEditMode,
    switchToViewMode,
    onSubmit: handleSubmit,
  };
};

export default useIncidentForm;
