import { Button, Form, Modal, Space } from 'antd';
import { IModal } from 'common/models/model.interface';
import React, { FC, useEffect, useState } from 'react';
import styles from './edit-notification-modal.module.scss';
import { FormWrapper } from 'common/components/form-wrapper/form-wrapper';
import { ReactComponent as SaveOutlined } from 'assets/icons/save.svg';
import residentsService from 'common/services/residents-service/residents.service';
import { IResidentUpdate } from 'common/services/residents-service/residents.service.types';
import { IEditNotifications } from 'common/models/notifications.interface';
import { EditNotificationsBlock } from 'common/components/edit-notifications-block/edit-notifications-block';
import { notification } from 'common/utils/notification';
import { notificationTypes, isNotificationEnabled } from 'common/constans/notification-types.constants';

const EditNotificationsModal: FC<IModal<IEditNotifications, any>> = ({ isOpen, onClose, title, initData }) => {
  const getNotificationType = (): string => {
    return Object.keys(notificationTypes).find((key) => initData?.[notificationTypes[key]]) || isNotificationEnabled;
  };

  const [notificationType, setNotificationType] = useState<string>(getNotificationType());
  const [form] = Form.useForm();

  useEffect(() => {
    if (form && isOpen) {
      form.setFieldsValue(initData);
      setNotificationType(getNotificationType());
    }
  }, [isOpen, form, initData]);

  const hideModal = (...rest: any) => {
    notification.destroy();
    if (onClose) {
      onClose(...rest);
    }
  };

  const onFinish = async (values: any) => {
    const residentToUpdate = {
      id: initData!.id,
      firstName: initData!.firstName,
      lastName: initData!.lastName,
      email: values.email,
      isEmailNotificationEnabled: notificationType === notificationTypes.isEmailNotificationEnabled,
      isSMSNotificationEnabled: notificationType === notificationTypes.isSMSNotificationEnabled,
      isPushNotificationEnabled: notificationType === notificationTypes.isPushNotificationEnabled,
      phones: values.phones,
    } as IResidentUpdate;

    await residentsService.updateResident(residentToUpdate);
    hideModal(true, residentToUpdate);
    notification.destroy();
    notification.success({ message: 'Notifications was successfully updated' });
  };

  return (
    <Modal centered title={title} width={550} open={isOpen} wrapClassName="custom-modal" closable={false}>
      <FormWrapper form={form} onFinish={onFinish} initialValues={initData} layout="vertical">
        <EditNotificationsBlock
          notificationType={notificationType}
          onChangeNotificationType={setNotificationType}
          withEmailInput
          form={form}
        />
        <Space className="footer">
          <Button type="link" onClick={() => hideModal(false)} className={styles.button}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" className={styles.button} icon={<SaveOutlined />}>
            Save
          </Button>
        </Space>
      </FormWrapper>
    </Modal>
  );
};

export default React.memo(EditNotificationsModal, (prevProps, nextProps) => {
  return prevProps.isOpen === nextProps.isOpen;
});
