import React from 'react';
import { Checkbox, Form, Input, Radio } from 'antd';
import { formatPhone } from 'common/helpers/phone.helper';
import styles from './edit-notifications-block.module.scss';
import { EditNotificationsBlockProps } from './edit-notifications-block.types';
import { RadioChangeEvent } from 'antd/lib';
import { editNotificationsValidation } from './edit-notifications.validations';
import { notificationTypes } from '../../constans/notification-types.constants';
import Feature from '../feature/feature';
import { FeatureName } from 'common/enums/feature-name.enum';

export const EditNotificationsBlock: React.FC<EditNotificationsBlockProps> = ({
  withEmailInput,
  notificationType,
  onChangeNotificationType,
  form,
}) => {
  const isSMSNotificationEnabled = notificationType === notificationTypes.isSMSNotificationEnabled;
  const isEmailNotificationEnabled = notificationType === notificationTypes.isEmailNotificationEnabled;

  const handleRadioChange = ({ target: { value } }: RadioChangeEvent) => {
    if (onChangeNotificationType) {
      onChangeNotificationType(value);
    }
  };

  return (
    <Radio.Group value={notificationType} className="vertical-radio-group" onChange={handleRadioChange}>
      <Feature name={FeatureName.SmsNotifications}>
        <Radio value="isSMSNotificationEnabled">SMS notification</Radio>
        <div className={`${styles.editNotificationsBlock} ${isSMSNotificationEnabled ? '' : styles.disabled}`}>
          <Form.List name="phones" rules={isSMSNotificationEnabled ? editNotificationsValidation.phones : undefined}>
            {(fields) => {
              return fields.map((field, index) => {
                const phone = form.getFieldValue(['phones', index]);
                return (
                  phone.number &&
                  phone.label && (
                    <div className={styles.phoneNumber} key={`notifications-phone-${index}`}>
                      <Form.Item {...field} name={[field.name, 'isNotificationEnabled']} valuePropName="checked">
                        <Checkbox disabled={!isSMSNotificationEnabled} />
                      </Form.Item>
                      <span>{formatPhone(phone.number)}</span>
                      <span className={`eiq-label ${phone.label?.toLowerCase()}`}>{phone.label}</span>
                    </div>
                  )
                );
              });
            }}
          </Form.List>
        </div>
      </Feature>
      <Radio value="isEmailNotificationEnabled">Email notification</Radio>
      {withEmailInput && (
        <Form.Item
          name="email"
          label="Email address"
          className={`${styles.smsNotificationsBody} ${isEmailNotificationEnabled ? '' : styles.disabled}`}>
          <Input name="email" placeholder="Enter email" disabled={!isEmailNotificationEnabled} />
        </Form.Item>
      )}
      <Radio value="isPushNotificationEnabled">Push notification</Radio>
      <Radio value="isNotificationEnabled">Do not disturb</Radio>
    </Radio.Group>
  );
};
