import { notification, Space, AutoComplete, Input, Button, Row, Col } from 'antd';
import { IVehicleRead } from 'common/services/residents-service/residents.service.types';
import React, { useState } from 'react';
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { IIncidentInvolvedVehicleSearchProps } from './incident-involved-vehicle-search.types';
import vehicleService from 'common/services/vehicle-service/vehicle.service';
import { IListResponse, IVehicleAdminRead } from 'common/services/vehicle-service/vehicle.service.types';

export const IncidentInvolvedVehicleSearch = ({ onSelected, onEnterManuallyClick }: IIncidentInvolvedVehicleSearchProps) => {
  const [data, setData] = useState<IVehicleRead[]>([]);

  const buildSearchQuery = (search?: string) => {
    if (!search) {
      return null;
    }

    return `licenseNumber:${search}*`;
  };

  const handleSearch = (newValue?: string) => {
    if (!newValue) {
      setData([]);
      return;
    }

    vehicleService
      .getVehicles(null, buildSearchQuery(newValue), null)
      .then((response: IListResponse<IVehicleAdminRead>) => setData(response.items.map((item) => item)))
      .catch((e) => {
        notification.error({
          message: 'Can not fetch vehicles',
        });
        console.log(e);
      });
  };

  const handleSelect = (id: string) => {
    const selectedItem = data.find((item) => item.id.toString() === id);

    if (selectedItem) {
      onSelected(selectedItem);
    }
  };

  return (
    <>
      <Space className="full-width" size={13}>
        <Row gutter={{ xs: 24, md: 8 }}>
          <Col xs={24}>
            <AutoComplete
              className="full-width"
              defaultActiveFirstOption={false}
              onSearch={handleSearch}
              onChange={handleSelect}
              dataSource={data.map((item) => ({
                text: `${item.licenseNumber} (${item.licenseState})`,
                value: item.id.toString(),
              }))}>
              <Input placeholder="Search license place" prefix={<SearchIcon />} />
            </AutoComplete>
          </Col>
        </Row>
      </Space>
      <Space className="full-width" size={13}>
        <p className="manual-info-add-label">
          If the resident vehicle or the guest vehicle involved in the incident is not found in EntranceIQ, please fill in the vehicle
          information manually.
        </p>
      </Space>

      <Space>
        <Button type="primary" className="primary-btn" icon={<EditIcon />} onClick={onEnterManuallyClick}>
          Enter Manually
        </Button>
      </Space>
    </>
  );
};
